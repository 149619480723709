const paramsPresent = (...rest) => rest.length && rest.every(item => !!item || item === 0)
const paramsIsNumber = (...rest) => rest.every(param => typeof +param === 'number' && !isNaN(param))

export class QuoteCalculator {
    constructor({ tax = 0 } = {}) {
        this.doors = { items: {}, totalPrice: 0, totalCost: 0 }
        this.totalPrice = 0
        this.totalCost = 0
        this.tax = +tax
    }

    setDoor = (name, doorParams) => {
        const { price, cost, count } = doorParams || {}
        if (!paramsPresent(name) && !paramsIsNumber(price, cost, count)) return

        const door = this.findDoor(name)

        if (door) {
            if (paramsPresent(price)) door.price = +price
            if (paramsPresent(cost)) door.cost = +cost
            if (paramsPresent(count)) door.count = +count
        }
        else if (!paramsPresent(price, cost, count)) return
        else {
            this.doors.items[name] = {
                price: +price,
                cost: +cost,
                count: +count,
            }
        }

        return this.calculateTotalDoor(name)
    }

    removeDoor = name => {
        if (!paramsPresent(name)) return

        const door = this.findDoor(name)
        if (!door) return

        delete this.doors.items[name]

        return this.calculateTotalDoors()
    }

    setDoorAccessory = (name, accessoryGroupName, accessoryName, accessoryParams) => {
        const { price, cost, count } = accessoryParams || {}
        if (!paramsPresent(name, accessoryGroupName, accessoryName, price, count, cost) && !paramsIsNumber(price, count, cost)) return

        const door = this.findDoor(name)
        if (!door) return

        const accessories = this.findOrCreateAccessories(door)
        const accessoryGroup = this.findOrCreateAccessoryGroup(accessories, accessoryGroupName)
        const accessory = this.findOrCreateAccessory(accessoryGroup, accessoryName)

        return this.calculateDoorAccessory(name, accessoryGroupName, accessory, accessoryParams)
    }

    taxChange = tax => {
        if (!paramsPresent(tax) && !paramsIsNumber(tax)) return

        this.tax = +tax

        return this.calculateTotal()
    }

    calculateDoorAccessory(name, accessoryGroupName, accessory, accessoryParams) {
        const { price, cost, count } = accessoryParams
        const accessoryTotalCost = +cost * +count
        const accessoryTotalPrice = +price * +count

        accessory.price = +price
        accessory.cost = +cost
        accessory.count = +count
        accessory.totalCost = accessoryTotalCost
        accessory.totalPrice = accessoryTotalPrice

        return { accessoryTotalPrice, accessoryTotalCost, ...this.calculateDoorAccessoryGroup(name, accessoryGroupName) }
    }

    calculateDoorAccessoryGroup(name, accessoryGroupName) {
        const accessoryGroup = this.findDoor(name)?.accessories?.items?.[accessoryGroupName]
        if (!accessoryGroup) return

        const [accessoryGroupTotalPrice, accessoryGroupTotalCost] = this.calculateTotalForItemsParent(accessoryGroup)

        return { accessoryGroupTotalPrice, accessoryGroupTotalCost, ...this.calculateDoorAccessories(name) }
    }

    calculateDoorAccessories(name) {
        const accessories = this.findDoor(name)?.accessories
        if (!accessories) return

        const [accessoriesTotalPrice, accessoriesTotalCost] = this.calculateTotalForItemsParent(accessories)

        return { accessoriesTotalPrice, accessoriesTotalCost, ...this.calculateTotalDoor(name) }
    }

    calculateTotalDoor(name) {
        const door = this.findDoor(name)
        if (!door) return

        const doorTotalPrice = door.price * door.count + (door?.accessories?.totalPrice || 0)
        const doorTotalCost = door.cost * door.count + (door?.accessories?.totalCost || 0)

        door.totalPrice = doorTotalPrice
        door.totalCost = doorTotalCost

        return { doorTotalPrice, doorTotalCost, ...this.calculateTotalDoors() }
    }

    calculateTotalDoors() {
        const [doorsTotalPrice, doorsTotalCost] = this.calculateTotalForItemsParent(this.doors)

        return { doorsTotalPrice, doorsTotalCost, ...this.calculateTotal() }
    }

    calculateTotal() {
        const totalPrice = this.doors.totalPrice * (this.tax / 100 + 1)
        const totalCost = this.doors.totalCost * (this.tax / 100 + 1)

        this.totalPrice = totalPrice
        this.totalCost = totalCost

        return { totalPrice, totalCost }
    }

    findDoor(name) {
        if (!paramsPresent(name)) return

        return this.doors?.items?.[name]
    }

    findOrCreateAccessories(door) {
        if (!paramsPresent(door)) return

        const accessories = door.accessories

        if (accessories) return accessories
        else return door.accessories = { items: {}, totalCost: 0, totalPrice: 0 }
    }

    findOrCreateAccessoryGroup(accessories, accessoryGroupName) {
        if (!paramsPresent(accessories, accessoryGroupName)) return

        const accessoryGroup = accessories.items[accessoryGroupName]

        if (accessoryGroup) return accessoryGroup
        else return accessories.items[accessoryGroupName] = { items: {}, totalCost: 0, totalPrice: 0 }
    }

    findOrCreateAccessory(accessoryGroup, accessoryName) {
        if (!paramsPresent(accessoryGroup, accessoryName)) return

        const accessory = accessoryGroup.items[accessoryName]

        if (accessory) return accessory
        else return accessoryGroup.items[accessoryName] = {}
    }

    calculateTotalForItemsParent(itemsParent) {
        const array = Object.values(itemsParent.items)
        const totalPrice = array.reduce((acc, item) => acc += item?.totalPrice || 0, 0)
        const totalCost = array.reduce((acc, item) => acc += item?.totalCost || 0, 0)

        itemsParent.totalPrice = totalPrice
        itemsParent.totalCost = totalCost

        return [totalPrice, totalCost]
    }
}
