import { QuoteCalculator } from './classes/quotes/accessory_calculator'

const numberToCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
}).format

$(document).on('ready turbolinks:load', function() {
    const $priceBar = $('#quotePriceBar')

    $(document).on('change', "#quote-customer-selector", function(e) {
        const $selector = $(e.target);
        const customerId = $selector.find('option:selected').val();

        $.get({
            headers: { 'Accept': 'text/javascript' },
            url: `/admin/customers/find`,
            data: { customer_id: customerId }
        })
    });

    if ($priceBar.length) {
        const { quoteId, tax } = $priceBar[0]?.dataset
        global.quoteCalculator = new QuoteCalculator({ tax })

        const $doors = $('[data-door-id]')

        $doors.each(function () {
            const { doorId, price, cost } = this.dataset
            const count = $(this).find('.door-count-field').val()

            quoteCalculator.setDoor(doorId, { price, cost, count })
        })

        $(document).on('change', ".accessory-count-field", function({ target }) {
            const $field = $(target)
            const doorId = $field.parents('.door-options').data('door-id')
            const accessoryGroup = $field.parents('.accessory-group-container').data('accessory-group')
            const { accessory, price, cost } = target.dataset
            const count = target.value
            const { accessoryTotalPrice, accessoryGroupTotalPrice } = quoteCalculator.setDoorAccessory(doorId, accessoryGroup, accessory, { price, cost, count })

            $field.parent().siblings('.accessory-total-price').html(numberToCurrency(accessoryTotalPrice))
            $field.parents('.accessory-group-container').find('.accessory-group-total-price').html(numberToCurrency(accessoryGroupTotalPrice))
            renderPriceBar()
        });

        $(document).on('change', ".door-count-field", function({ target }) {
            const $doorWrapper = $(target).parents('.door-options')
            const doorId = $doorWrapper.data('door-id')
            const count = target.value

            $.ajax({
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                    'Accept': 'text/javascript'
                },
                url: `/admin/quote_doors/${doorId}`,
                data: { quote_door: { count } },

            }).done(function() {
                const { doorTotalPrice } = quoteCalculator.setDoor(doorId, { count })

                $doorWrapper.find('.door-total-price').html(numberToCurrency(doorTotalPrice))
                renderPriceBar()
            });
        });

        $(document).on('input', "#tax-value-field", function({ target }) {
            const { value } = target

            quoteCalculator.taxChange(value);
            renderPriceBar()
        });

        $(document).on('change', "#tax-value-field", function({ target }) {
            const { value: tax_value } = target

            $.ajax({
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                    'Accept': 'text/javascript'
                },
                url: `/admin/quotes/${quoteId}/update_tax_value`,
                data: { quote: { tax_value } },

            }).done(function() {
                quoteCalculator.taxChange(tax_value);
                renderPriceBar()
            });
        });
    }

    $('[id$="-custom-item-list"').on('cocoon:after-insert', function({ timeStamp }, added_task) {
        const $toggle = $(added_task).find('.custom-toggle')
        const $toggleInput = $toggle.find('input[type="checkbox"]')
        const $collapse = $(added_task).find('.collapse')

        replaceIndex($toggle, 'aria-controls')
        replaceIndex($toggle, 'data-target')
        replaceIndex($toggleInput, 'id')
        replaceIndex($collapse, 'id')
        replaceIndex($collapse, 'aria-labelledby')

        function replaceIndex($element, attr) {
            $element.attr(attr, $element.attr(attr).replace('{index}', timeStamp))
        }
    })

    $(document).on('change', '.custom-item-fields input[type="number"]', function () {
        const $parent = $(this).parents('.custom-item-fields')
        const $fields = $parent.find('input[type="number"]')
        const $total = $parent.find('.custom-item-total')
        const calculatedTotal = eval([...$fields].map(field => field.value).join('*')) || 0

        $total.html(numberToCurrency(calculatedTotal))
    })
})

$(document).on('hidden.bs.collapse', '.collapse[id$="discount"]', function () {
    $(this).find('input[type="number"]').val(0)
})

global.renderPriceBar = function () {
    if (!quoteCalculator) return
    const { doors: { totalPrice: subtotalPrice, totalCost: subtotalCost }, totalPrice } = quoteCalculator

    $('#doorSubtotal').html(numberToCurrency(subtotalPrice));
    $('#costSubtotal').html(numberToCurrency(subtotalCost));
    $('#doorTotal').html(numberToCurrency(totalPrice));
}
