$(document).on('ready turbolinks:load', function() {
    $(document).on('change', '.door-options-selector', function(e) {
        e.stopImmediatePropagation()
        submitDoorSelectionForm(e.target);
    })

    $(document).on('click', '.selector-preview-item', function(e) {
        e.stopImmediatePropagation()
        const { itemId, selectorType } = e.target.parentNode.dataset
        const selector = $('#doorOptionsSelectors').find(`#${selectorType} select`)[0]

        selector.value = itemId
        $('#quoteOptionPreloadModal').modal('hide');

        submitDoorSelectionForm(selector);
    })
})

const submitDoorSelectionForm = (targetedSelector) => {
    const $targetedSelector = $(targetedSelector)

    if (targetedSelector.value) {
        const $imageContainer = $('#generatedDoor .image')
        const quoteDoorId = $('#doorOptionsSelectors').data('quote-door-id')
        const next_selector = $targetedSelector.parents('.door-selector').next('.door-selector').find('.form-group').attr('id')
        let door = {}

        $('.door-options-selector:not(:disabled)').each(function(i, selector) {
            $selector = $(selector);
            door[$selector.attr('name')] = $selector.val();
        });

        door.feet_width = $('#widthFeetField').val();
        door.inches_width = $('#widthInchesField').val();
        door.feet_height = $('#heightFeetField').val();
        door.inches_height = $('#heightInchesField').val();

        $imageContainer.addClass('image-loading')
        $.ajax({
            method: 'PATCH',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                'Accept': 'text/javascript'
            },
            url: `/admin/quote_doors/${quoteDoorId}/assign_image`,
            data: { door, next_selector },
            success: function () {
                $imageContainer.removeClass('image-loading')
            },
            error: function () {
                $imageContainer.removeClass('image-loading')
            }
        });
    } else alert("You must select")
}
