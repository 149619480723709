$(document).on('ready turbolinks:load', function() {
    $(document).on('change', '#embossing-insulation-selector', function(e) {
        const insulation_id = $(e.target).val();
        const $form = $(e.target).parents('form');

        if (insulation_id) {
            $.get({
                headers: { 'Accept': 'text/javascript' },
                url: "/admin/door_embossings/find",
                data: { insulation_id }
            })
        } else {
            $form.find('#all_embossing_style_ids').prop('disabled', true).prop('checked', false)
            $form.find('.embossing-style-checkboxes').prop('disabled', true).prop('checked', false)
            $form.find('.embossing-count-field').prop('disabled', true)
        }
    })
})
