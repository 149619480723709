$(document).on('ready turbolinks:load', function() {
  initInputFiles()

  $(document).on('submit', '#confirmForm', function(e) {
    e.preventDefault();
    const $thisPasswordField = $(this).find('input[type="password"][name="password"]')
    const { targetForm } = $(this).closest('#confirmModal')[0].dataset
    const $form = $(targetForm);
    const $targetFormPasswordField = $form.find('input[type="hidden"][name="password"]');

    $targetFormPasswordField.val($thisPasswordField.val());
    Rails.fire($form[0], 'submit')
  })
})

global.submitThisForm = function (el, remote = false) {
  if (!el) return
  const form = $(el).closest('form')[0]

  if (remote) Rails.fire(form, 'submit')
  else form.submit('rails')
}

global.initInputFiles = function () {
  const inputs = document.querySelectorAll('.input-file')

  inputs.forEach(input => {
    const label	= input.nextElementSibling.firstChild
    const labelVal = label.innerHTML

    input.addEventListener('change', function({ target }) {
      const { files, dataset } = target
      let fileName = ''

      if (files?.length > 1) {
        fileName = (dataset.multipleCaption || 'Selected {count} files').replace('{count}', files.length)
      } else {
        fileName = target.value.split('\\').pop()
      }

      label.innerHTML = fileName || labelVal
      label.title = fileName || ''
    })
  })
}

global.initTextEditor = function () {
  tinymce.remove();
  tinymce.init({
    selector: '.tinymce',
    height: 200,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
      toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
  });
}
