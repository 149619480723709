global.flash = function ({ type, message, title = null }) {
  const types = {
    notify: {
      title: 'Success',
      color: 'green'
    },
    alert: {
      title: 'Error',
      color: 'red'
    }
  }
  const delay = 15000
  const messages = [JSON.parse(message)].flat().join('<hr/>')
  const $toast = $(`
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${delay}" data-autohide="true">
      <div class="toast-header px-4">
        <div class="bg-${types[type].color} rounded p-2 mr-2"></div>
        <strong class="mr-auto">${title || types[type].title}</strong>
        <button type="button" class="ml-2 m2-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="toast-body px-4">
        ${messages}
      </div>
    </div>
  `)
  const $toasts = $('#toasts')

  $toast.on('show.bs.toast', () => $toasts.append($toast))
  $toast.on('hidden.bs.toast', () => $toast.remove())
  $toast.toast('show')
}
