// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
global.Rails = require('@rails/ujs')

ActiveStorage.start()
Turbolinks.start()
Turbolinks.setProgressBarDelay(0)
Rails.start()

global.$ = $
global.jQuery = jQuery
import "cocoon";
import "@fortawesome/fontawesome-free";
require("channels")
require("chart.js")
require("javascripts/Chart.extension.js")
require("javascripts/jquery-scrollLock.min.js")
require("javascripts/jquery.scrollbar.min.js")
require("javascripts/argon.js")

import "javascripts/ui/flash";

import "javascripts/general";
import "javascripts/option_filter";
import "javascripts/door_builder";
import "javascripts/quotes";
import "javascripts/door_sections";
import "javascripts/door_filter";
import "javascripts/door_embossings";
import "javascripts/door_updater";
