$(document).on('ready turbolinks:load', function() {
    $(document).on('change', '.filter-all', function ({ target }) {
        const isCheckedAll = target.checked
        const $parent = $(target).parents('.filters')
        const $filters = $parent.find('.filter-selector')
        const isIndeterminate = $parent.hasClass('filters--indeterminate')

        $filters.prop('disabled', isIndeterminate ? false : isCheckedAll).prop('checked', isCheckedAll).prop('indeterminate', false)
    })

    $(document).on('change', '.filter-selector', function ({ target }) {
        const $parent = $(target).parents('.filters')
        updateCheckedAllSection($parent)
    })

    $(document).on('change', '#pricePercentCheckbox', function({ target }) {
        const isChecked = target.checked
        const $submitButton = $('#updatePriceSubmit');
        const $priceField = $('#doorPriceValue');
        const $costField = $('#doorCostValue');
        const $percentField = $('#doorPricePercent');

        $priceField.prop('disabled', isChecked);
        $costField.prop('disabled', isChecked);
        $percentField.prop('disabled', !isChecked);
        $('#pricePercentWrapper')[isChecked ? 'removeClass' : 'addClass']('d-none')

        if ((!$priceField.is(':disabled') && $priceField.val() > 0) && (!$costField.is(':disabled') && $costField.val() > 0) || ($percentField.val() > 0) && !$percentField.is(':disabled')) {
            $submitButton.removeClass('disabled');
            $submitButton.prop('disabled', false)
        } else {
            $submitButton.addClass('disabled');
            $submitButton.prop('disabled', true)
        }
    })

    $(document).on('click', '#updatePriceSubmit', function(e) {
        e.preventDefault();
        $('#editPriceModal').modal('hide');
        $('#confirmModal').modal('show');
    })
})

global.fillIdsInput = function () {
    const widthIds = [...$('.doorList .door-item')].map(item => item.dataset.doorItemWidthId)
    const uniqueWidthIds = [...new Set(...widthIds)]

    $('#doorIds').val(uniqueWidthIds);
}

global.updateCheckedAllSection = function (parent) {
    const $parent = $(parent)
    const $checkedAllInput = $parent.find('.filter-all')
    const $filters = $parent.find('.filter-selector')
    const isCheckedAll = [...$filters].every(item => item.checked)
    const isSomeChecked = [...$filters].some(item => item.checked)
    const isIndeterminate = $parent.hasClass('filters--indeterminate') && isSomeChecked

    $checkedAllInput.prop('indeterminate', isCheckedAll ? false : isIndeterminate).prop('checked', isCheckedAll)
}
