$(document).on('ready turbolinks:load', function() {

    $(document).on('change', '.price-field', function () {
        const $submitButton = $('#updatePriceSubmit');
        const $priceField = $('#doorPriceValue');
        const $costField = $('#doorCostValue');
        const $percentField = $('#doorPricePercent');

        if ((isValidField($priceField) && isValidField($costField)) || isValidField($percentField)) {
            $submitButton.removeClass('disabled');
            $submitButton.prop('disabled', false)
        } else {
            $submitButton.addClass('disabled');
            $submitButton.prop('disabled', true)
        }
    })
})

const isValidField = (field) => {
   return !field.is(':disabled') && field.val() > 0
}
